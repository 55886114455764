var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageSection',{attrs:{"bg-color":"#2c365c","full-bleed-bg":""}},[_c('VLayout',{attrs:{"row":"","py-4":"","wrap":""}},[_c('VFlex',{staticClass:"treps-navy--text",attrs:{"xs12":"","sm7":"","md7":"","xl4":"","offset-xl2":"","pa-4":""}},[_c('BasePageContent',{staticClass:"white--text pb-4 mb-4",attrs:{"content-key":"contact_form_title","default-content":"Say Hey.","tag-class":"header-4 form-greeting","tag":"h2"}}),_c('BasePageContent',{attrs:{"content-key":"contact_form_description","default-content":"Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. ","tag":"p","tag-class":"contact-form-desc white--text"}})],1),_c('VFlex',{attrs:{"xs12":"","sm5":"","xl4":"","pa-4":""}},[_c('ContactForm',{staticClass:"treps-contact-form",attrs:{"field-color":"white","color":"white","message-component":_vm.customMsgComponent,"text-field-attrs":{
          outline: true,
          color: 'white',
          dark: true,
        }},scopedSlots:_vm._u([{key:"success-msg",fn:function(ref){
        var dismiss = ref.dismiss;
return [_c('h2',{staticClass:"white--text"},[_vm._v("Thank you.")]),_c('p',{staticClass:"r-headline white--text"},[_vm._v("We'll get back to you soon.")]),_c('BaseButtonOutline',{staticClass:"ml-0",on:{"click":dismiss}},[_vm._v("Great!")])]}},{key:"submit-btn",fn:function(ref){
        var sending = ref.sending;
return [_c('BaseButton',{staticClass:"ml-0",attrs:{"color":"treps-blue","type":"submit","loading":sending}},[_vm._v("Submit")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }