<template>
  <BasePageSection bg-color="#2c365c" full-bleed-bg>
    <VLayout row py-4 wrap>
      <VFlex xs12 sm7 md7 xl4 offset-xl2 pa-4 class="treps-navy--text">
        <BasePageContent
          content-key="contact_form_title"
          default-content="Say Hey."
          tag-class="header-4 form-greeting"
          class="white--text pb-4 mb-4"
          tag="h2"
        />

        <BasePageContent
          content-key="contact_form_description"
          default-content="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
          tag="p"
          tag-class="contact-form-desc white--text"
        />
      </VFlex>
      <VFlex xs12 sm5 xl4 pa-4>
        <ContactForm
          class="treps-contact-form"
          field-color="white"
          color="white"
          :message-component="customMsgComponent"
          :text-field-attrs="{
            outline: true,
            color: 'white',
            dark: true,
          }"
        >
          <template #success-msg="{ dismiss }">
            <h2 class="white--text">Thank you.</h2>
            <p class="r-headline white--text">We'll get back to you soon.</p>
            <BaseButtonOutline class="ml-0" @click="dismiss"
              >Great!</BaseButtonOutline
            >
          </template>
          <template #submit-btn="{ sending }">
            <BaseButton
              color="treps-blue"
              class="ml-0"
              type="submit"
              :loading="sending"
              >Submit</BaseButton
            >
          </template>
        </ContactForm>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import ContactForm from '@components/ContactForm'
import { VTextarea } from '@vuetify'
export default {
  name: 'ContactSection',
  components: {
    ContactForm,
    // eslint-disable-next-line
    VTextarea,
  },
  data() {
    return {
      customMsgComponent: VTextarea,
    }
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@styleMixins';

.treps-contact-form {
  .v-text-field .v-input__control .v-input__slot {
    input {
      max-height: 2.5rem;
      font-size: 1.3rem;
      letter-spacing: 0.029em;
    }
    .v-label:not(.v-label--active) {
      height: 1.4rem;
      font-size: 1.3rem;
    }
    &::before,
    &::after {
      border-color: white;
      transform: scaleX(1);
    }
  }
}
</style>
